import React from 'react'
import Layout from '../../components/layout/layout'
import { Link } from 'gatsby'
import SEO from "../../components/seo"
import CCBreadcrumb from '../../components/layout/breadcrumb'

import Alert from 'react-bootstrap/Alert'
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Card from 'react-bootstrap/Card'


const IndexPage = (props) => (
  <Layout location={props.location.pathname}>
    <SEO title="Carbon Connections - The Carbon Cycle and the Science of Climate" />
    <CCBreadcrumb
      pathname={props.location.pathname}
      title={'Teacher Support'}
      replace={props.replace}
    />
    <Container fluid className="mb-5">
      <Row className="d-flex">
        <Col>
          <Alert
            className="w-100"
            variant="warning"
          >
            BSCS is maintaining this educational resource for archival purposes. Some information may be out of date. We are no longer supporting the resource for classroom use.
          </Alert>
        </Col>
      </Row>
      <Row>
        <Col>
          <Card>
            <Card.Body>
              <Row>
                <Col>
                  <Card>
                    <Card.Header className="cardHeader">
                      <h1 className="headerFontBlue">Teacher Support</h1>
                    </Card.Header>
                    <Card.Body className="cardBodyBackground">
                      <h2>Teacher Pages</h2>
                      <p>
                        The <Link to="../teacher-resources/">teacher pages</Link> are just for you, the teacher.
                      </p>

                      <p>
                        The teacher pages include:
                        <ul>
                          <li>Advanced preparation notes</li>
                          <li>Chapter organizers</li>
                          <li>Lesson indicators and outcomes</li>
                          <li>Materials lists</li>
                          <li>Videos of select activities for teacher preparation</li>
                          <li>Answers to student-page questions</li>
                          <li>Unit test or quiz questions</li>
                          <li>Classroom discussion PowerPoint's</li>
                        </ul>
                      </p>

                      <h2>Videos &amp; Interactives</h2>
                      <p>
                        In order to watch the video and use the interactives on this site, make sure you have the latest version of <a href="https://get.adobe.com/flashplayer/" target="_blank" rel="noopener noreferrer">Adobe<sup>&reg;</sup> Flash<sup>&reg;</sup> Player&nbsp;<sup><i className="fas fa-external-link-alt"></i></sup></a> installed.
                      </p>

                      <p>
                        We expect that <em>Carbon Connections</em> will entail about three to four weeks of instruction (three units, five lessons per unit). Below are a few items to help you with your planning.
                      </p>

                      <h2>Web sites</h2>
                      <p>
                        Check that your school doesn't have a firewall blocking the following sites:
                        <ul>
                          <li>
                            <em>Carbon Connections</em>: <a href="http://carbonconnections.bscs.org/" target="_blank" rel="noopener noreferrer">http://carbonconnections.bscs.org/&nbsp;<sup><i className="fas fa-external-link-alt"></i></sup></a>
                          </li>
                          <li>
                            <em>Carbon Connections</em> teacher pages: <a href="http://carbonconnectionsteacherpage.bscs.org/" target="_blank" rel="noopener noreferrer">http://carbonconnectionsteacherpage.bscs.org/&nbsp;<sup><i className="fas fa-external-link-alt"></i></sup></a>
                          </li>
                          <li>
                            <em>The Global Carbon Budget</em>: <a href="http://carboncycle.aos.wisc.edu/carbon-budget-tool/" target="_blank" rel="noopener noreferrer">http://carboncycle.aos.wisc.edu/carbon-budget-tool/&nbsp;<sup><i className="fas fa-external-link-alt"></i></sup></a>
                          </li>
                          <li>
                            Carbon Calculator: <a href="https://www.nature.org/en-us/get-involved/how-to-help/consider-your-impact/carbon-calculator/" target="_blank" rel="noopener noreferrer">https://www.nature.org/en-us/get-involved/how-to-help/consider-your-impact/carbon-calculator/&nbsp;<sup><i className="fas fa-external-link-alt"></i></sup></a>
                          </li>
                        </ul>
                      </p>
                    </Card.Body>
                  </Card>
                </Col>
              </Row>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Container>
  </Layout>
)

export default IndexPage
